<template>
  <div class="about">
    <h2>About</h2>
    <p>In my role, I’ve integrated cutting-edge technology and innovative processes to transform our approach to the healthcare industry. My focus on aligning technology with business objectives has been key in enhancing performance and reliability, ensuring our platform remains agile and efficient.</p>
    <p>My mission is to bridge the gap between technology and business. By advocating for engagement at every stage, I’ve cultivated a culture of efficiency and quality that drives strategic decisions and business growth.</p>
    <p>With over 15 years of experience leading DevOps, Platform Engineering, Site Reliability, Security, and Data Teams, I’ve consistently delivered impactful results. These include reducing system downtime, improving deployment speeds, bolstering security measures, and optimizing data processing. Let’s connect and explore how we can achieve exceptional results together.</p>
  </div>
</template>

<script>
export default {
  name: 'AboutMe'
};
</script>

<style scoped>
.about {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: left;
  margin-top: 20px;
}
</style>