<template>
  <div class="skills">
    <h2>Skills</h2>
    <div class="skills-grid">
      <div class="skill" v-for="skill in skills" :key="skill">
        {{ skill }}
      </div>
    </div>
    <p class="more-skills">...and many more.</p>
  </div>
</template>

<script>
export default {
  name: 'UserSkills',
  data() {
    return {
      skills: [
        'Backend Development', 'AWS Solutions Architect', 'Kubernetes', 'Docker', 'Python',
        'DevOps', 'CloudOps', 'Platform Engineering', 'Kafka', 'Ruby on Rails'
      ]
    };
  }
};
</script>

<style scoped>
.skills {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: left;
  margin-top: 20px;
}

.skills-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.skill {
  background: #2c3e50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  flex: 1 0 30%;
}

.more-skills {
  text-align: center;
  margin-top: 20px;
  font-style: italic;
  color: #666;
}
</style>