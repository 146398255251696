<template>
  <div class="contact">
    <h2>Contact Me</h2>
    <p>If you'd like to get in touch, please reach out via email:</p>
    <p><a href="mailto:guillermo.hernandez@upsao.com">guillermo.hernandez@upsao.com</a></p>
  </div>
</template>

<script>
export default {
  name: 'ContactMe'
};
</script>

<style scoped>
.contact {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center; /* Center align the content */
  margin: 20px auto; /* Center the contact box within its container */
}
.contact p {
  margin: 10px 0;
}
.contact a {
  color: #2c3e50;
  text-decoration: none;
}
.contact a:hover {
  text-decoration: underline;
}
</style>