<template>
  <header class="header">
    <h1>{{ title }}</h1>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      title: 'GH Hernandez - DevOps, Cloud & Platform Engineering Leader'
    };
  }
};
</script>

<style scoped>
.header {
  background: #2c3e50;
  padding: 20px;
  color: white;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-size: 1em;
}
</style>