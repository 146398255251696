<template>
  <div id="app">
    <AppHeader />
    <div class="content">
      <div class="image-section">
        <img src="@/assets/profile.jpg" alt="Profile Picture" class="profile-picture"/>
      </div>
      <div class="text-section">
        <h1>Looking for a Skilled Engineer? Or a Technology Leader?</h1>
        <p>I bring over 15 years of experience in DevOps, cloud computing, platform engineering, and SRE. My focus is on delivering innovative solutions that enhance performance and drive growth.</p>
        <p>Let's connect to see how my expertise can help your organization succeed.</p>
      </div>
    </div>
    <div class="content">
      <AboutMe />
    </div>
    <div class="content">
      <UserSkills />
    </div>
    <div class="content">
      <ContactMe />
    </div>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import UserSkills from './components/UserSkills.vue';
import AboutMe from './components/AboutMe.vue';
import ContactMe from './components/ContactMe.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    UserSkills,
    AboutMe,
    ContactMe
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  border-radius: 10px;
}

.text-section {
  max-width: 600px;
  text-align: left;
  padding-left: 40px; /* Add padding between the text and image */
}

.text-section h1 {
  font-size: 2.2em;
  margin-bottom: 15px;
}

.text-section p {
  font-size: 1.1em;
  margin-bottom: 15px;
}

.image-section {
  flex-shrink: 0; /* Prevent the image from shrinking */
}

.profile-picture {
  width: 250px;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    text-align: center;
  }

  .text-section {
    padding-left: 0; /* Remove padding for smaller screens */
    margin-top: 20px;
  }
}
</style>